import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    padding: '12px 0px',
    fontSize:18,
    fontWeight:400,
    width: '100%',
    boxShadow: '0px 3px 6px #00000029',
    background: 'rgba(103, 218, 216, 0.15)',
    color: '#68DBDA'
  },
  redeemButtonStyle: {
    padding: '12px 0px',
    fontSize:18,
    fontWeight:400,
    width: '100%',
    boxShadow: '0px 3px 6px #00000029',
    background: 'rgba(103, 218, 216, 0.15)',
    color: '#68DBDA'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    margin: 'auto',
  },
}));

export default useStyles;
