import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import Tooltip from "@material-ui/core/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { Typography, makeStyles } from "@material-ui/core";
import {
    TokenAmount as TokenAmountBSC,
    Token as TokenBSC,
    Fetcher as FetcherBSC,
    ChainId as ChainIdBSC,
    Route as RouteBSC,
} from "@pancakeswap-libs/sdk";
import {
    TokenAmount as TokenAmountV2,
    Token as TokenV2,
    Fetcher as FetcherV2,
    ChainId as ChainIdV2,
    Route as RouteV2,
} from "@pancakeswap-libs/sdk-v2";
import bigNumber from "bignumber.js";
import AppHeader from "../containers/AppHeader/AppHeader";
import StakeCard from "../containers/StakeCard";
import TokenStatusCard from "../containers/TokenStatusCard";
import TransactionPanel from "../containers/TransactionPanel";
import LPTokensTransaction from "../containers/LPTokensTransaction";
import Refresh from "../assets/loader.svg";
import {
    contractAddressesObj,
    truncateDecimals,
    multiplier,
    usdtMultiplier,
    totalTokens,
} from "../constants";
import Loader from "../assets/loadingIconWhite.bin";
import { ethers } from "ethers";
import moment from "moment";
import { createAction } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import ContractInitialization from "../utils/contractInitialize";
import {
    getMTLXRewards,
    updateStakedAmount,
    getStakedAmount,
    getMtlxLeft,
} from "../utils";
import Migrate from "../containers/Migrate";

const connectWallet = createAction("CONNECT_WALLET");

const useStyles = makeStyles((theme) => ({
    content: {
        position: "relative",
        zIndex: 10,
        gridGap: "50px",
        marginBottom: "2%",
        padding: 0,
    },
    paper: {
        background: "#222330",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "5px",
        opacity: 1,
        padding: theme.spacing(4, 3),
    },
    typography: {
        color: "#9098AC",
        textAlign: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    title: {
        fontFamily:
            '"GTWalsheimMedium", "Roboto", "Helvetica", "Arial", sans-serif',
        color: "white",
        fontSize: 24,
    },
    gridStyle: {
        textAlign: "center",
    },
    addressBox: {
        border: "1px solid #4A4D5F",
        boxSizing: "border-box",
        background: "transparent",
        width: "178px",
        float: "right",
        textAlign: "center",
        color: "white",
        "& .MuiFormControl-root": {
            "& .MuiInput-underline:before": {
                borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
                borderBottom: "none",
            },
            "& .MuiInput-underline:focus": {
                borderBottom: "none",
            },
            "& .MuiInput-underline:hover": {
                borderBottom: "none",
            },
        },
    },
    flexDiv: {
        display: "flex",
        margin: "64px 0 24px",
    },
    heading: {
        color: "white",
        fontWeight: "bold",
        fontFamily:
            '"GTWalsheimRegular", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    select: {
        color: "white",
        fontWeight: 300,
        "&:before, &:after, &:hover, &:focus": {
            borderBottom: "none",
        },
    },
}));

const App = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [stakedAmount, setStakedAmount] = useState(0.0);
    const [currentValue, setCurrentValue] = useState(0.0);
    const [valueIncrease, setValueIncrease] = useState(0.0);
    const [loadingText, setLoadingText] = useState("Loading");
    const [poolShare, setPoolShare] = useState(0);
    const [usdtBalance, setUSDTBalance] = useState(0);
    const [lpTokens, setLPTokens] = useState(0.0);
    const [APY, setAPY] = useState(0.0);
    const [mtlx, setMTLX] = useState(0.0);
    const [mtlxInUSDT, setMTLXInUSDT] = useState(0.0);
    const [mtlxBalance, setMTLXBalance] = useState(0.0);
    const [lpTokensStaked, setLPTokensStaked] = useState(0.0);
    const [date, setDate] = useState(moment().format("MMM DD YYYY hh:mm a"));
    const [mtlxValue, setMtlxValue] = useState(0.0);
    const [tokensLeftToFarm, setTokensLeftToFarm] = useState(0.0);
    const [tokensPercent, setTokensPercent] = useState(0.0);
    const [rewardsCollected, setRewardsCollected] = useState(0.0);
    const [rewardsCollectedUSDT, setRewardsCollectedUSDT] = useState(0.0);
    const [pool] = useState("0");
    const [copied, setCopied] = useState(false);
    const [instance, setInstance] = useState({});
    const [isMigrated, setMigrated] = useState(false);
    // const [networkSelectedName, setNetworkSelectedName] = useState(networkName);

    let contractAddresses = contractAddressesObj[56];
    let provider = null;
    if (props.networkID === 56) {
        provider = new ethers.providers.JsonRpcProvider(
            "https://bsc-dataseed.binance.org/"
        );
    } else {
        provider = new ethers.providers.InfuraProvider(
            "mainnet",
            process.env.REACT_APP_INFURA_PROVIDER
        );
    }

    // const handlePoolChange = (event) => {
    //   setPool(event.target.value);
    // };

    const onCopy = () => {
        if (!copied) {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    };

    useEffect(() => {
        async function fetchData() {
            const value = await ContractInitialization(56);
            setInstance(value);
        }
        fetchData();
        connectWallet();
    }, []);

    useEffect(() => {
        if (Object.keys(instance).length && props.networkID) {
            updateAllBalances();
        }
        // eslint-disable-next-line
    }, [instance, props.networkID]);

    // const handleNetworkChange = (event) => {
    //   contractAddresses = contractAddressesObj[parseInt(event.target.value)];
    //   // setNetworkSelectedName(event.target.value === '1' ? 'Mainnet' : 'Binance Smart Chain');
    //   setNetworkSelected(event.target.value);
    // };

    useEffect(() => {
        if (Object.keys(instance).length && props.networkID) {
            updateAllBalances();
        }
        // eslint-disable-next-line
    }, [isMigrated]);

    const getStakeCardBalances = async () => {
        try {
            if (!isMigrated) {
                //Checking if LP tokens are migrated
                const oldUserInfo =
                    await instance.amplifyContractInstance.methods
                        .userInfo(parseInt(pool), props.user.address)
                        .call();
                const tokensStaked = new bigNumber(oldUserInfo.amount)
                    .div(new bigNumber(10 ** 18))
                    .toNumber();
                const tokensInWallet = await instance.lpContractInstance.methods
                    .balanceOf(props.user.address)
                    .call();
                if (parseFloat(tokensInWallet) === 0 && tokensStaked === 0) {
                    setMigrated(true);
                    return;
                }
            }
			const userInfo = await instance.amplifyContractInstance.methods
			// .userInfo(parseInt(pool), props.user.address)
			.userInfo(isMigrated ? 1 : parseInt(pool), props.user.address)
			.call();

            let usdtToken = null;
            let mtlxToken = null;
            let ethToken = null;

            if (!isMigrated) {
                usdtToken = new TokenBSC(
                    ChainIdBSC.MAINNET,
                    contractAddresses.usdt,
                    multiplier,
                    "BUSD",
                    "BUSD Token"
                );
                mtlxToken = new TokenBSC(
                    ChainIdBSC.MAINNET,
                    contractAddresses.mtlx,
                    multiplier,
                    "MTLX",
                    "MTLX-ERC20"
                );
            } else {
                usdtToken = new TokenV2(
                    ChainIdV2.MAINNET,
                    contractAddresses.usdt,
                    multiplier,
                    "BUSD",
                    "BUSD Token"
                );
                mtlxToken = new TokenV2(
                    ChainIdV2.MAINNET,
                    contractAddresses.mtlx,
                    multiplier,
                    "MTLX",
                    "MTLX-ERC20"
                );
            }

            let firstToken = usdtToken;
            if (pool === "1") {
                firstToken = ethToken;
            }

            let pair = null;

            if (!isMigrated) {
                pair = await FetcherBSC.fetchPairData(
                    firstToken,
                    mtlxToken,
                    provider
                );
            } else {
                pair = await FetcherV2.fetchPairData(
                    firstToken,
                    mtlxToken,
                    provider
                );
            }

            const lpTokensTotalSupply =
                pool === "0"
                    ? !isMigrated
                        ? await instance.lpContractInstance.methods
                              .totalSupply()
                              .call()
                        : await instance.pancakePairContractInstance.methods
                              .totalSupply()
                              .call()
                    : await instance.lpethContractInstance.methods
                          .totalSupply()
                          .call();

            const kLast =
                pool === "0"
                    ? !isMigrated
                        ? await instance.lpContractInstance.methods
                              .kLast()
                              .call()
                        : await instance.pancakePairContractInstance.methods
                              .kLast()
                              .call()
                    : await instance.lpethContractInstance.methods
                          .kLast()
                          .call();

            let lpToken = null;

            if (!isMigrated) {
                lpToken = new TokenBSC(
                    ChainIdBSC.MAINNET,
                    contractAddresses.lp,
                    multiplier,
                    pool === "0"
                        ? await instance.lpContractInstance.methods
                                  .symbol()
                                  .call()
                        : await instance.lpethContractInstance.methods
                              .symbol()
                              .call(),
                    pool === "0"
                        ? await instance.lpContractInstance.methods
                                  .name()
                                  .call()
                        : await instance.lpethContractInstance.methods
                              .name()
                              .call()
                );
            } else {
                lpToken = new TokenV2(
                    ChainIdV2.MAINNET,
                    contractAddresses.lpV2,
                    multiplier,
                    pool === "0"
                        ? await instance.pancakePairContractInstance.methods
                                  .symbol()
                                  .call()
                        : await instance.lpethContractInstance.methods
                              .symbol()
                              .call(),
                    pool === "0"
                        ? await instance.pancakePairContractInstance.methods
                                  .name()
                                  .call()
                        : await instance.lpethContractInstance.methods
                              .name()
                              .call()
                );
            }

            let first = null;
            let mtlx = null;

            if (!isMigrated) {
                first = pair.getLiquidityValue(
                    firstToken,
                    new TokenAmountBSC(lpToken, lpTokensTotalSupply.toString()),
                    new TokenAmountBSC(lpToken, userInfo.amount),
                    true,
                    kLast
                );
                mtlx = pair.getLiquidityValue(
                    mtlxToken,
                    new TokenAmountBSC(lpToken, lpTokensTotalSupply.toString()),
                    new TokenAmountBSC(lpToken, userInfo.amount),
                    true,
                    kLast
                );
            } else {
                first = pair.getLiquidityValue(
                    firstToken,
                    new TokenAmountV2(lpToken, lpTokensTotalSupply.toString()),
                    new TokenAmountV2(lpToken, userInfo.amount),
                    true,
                    kLast
                );

                mtlx = pair.getLiquidityValue(
                    mtlxToken,
                    new TokenAmountV2(lpToken, lpTokensTotalSupply.toString()),
                    new TokenAmountV2(lpToken, userInfo.amount),
                    true,
                    kLast
                );
            }

            let route = null;
            if (!isMigrated) {
                route = new RouteBSC([pair], mtlxToken);
            } else {
                route = new RouteV2([pair], mtlxToken);
            }

            let currentAmount = new bigNumber(first.toExact())
                .plus(
                    new bigNumber(mtlx.toExact()).multipliedBy(
                        route.midPrice.toSignificant(8)
                    )
                )
                .toNumber();
            const lpTokensStakedAmount = new bigNumber(userInfo.amount)
                .div(new bigNumber(10 ** multiplier))
                .toNumber();

            let stakedValue = await getStakedAmount(
                props.user.address,
                pool === "0"
                    ? props.networkID === 56
                        ? "BUSD"
                        : "USDT"
                    : "ETH",
                props.networkID
            );
            if (stakedValue <= 0.001) {
                setStakedAmount(new bigNumber(currentAmount).toString());
                stakedValue = new bigNumber(currentAmount).toString();
            } else if (stakedValue && lpTokensStakedAmount) {
                setStakedAmount(new bigNumber(stakedValue).toString());
                stakedValue = new bigNumber(stakedValue).toString();
            } else if (currentAmount) {
                setStakedAmount(new bigNumber(currentAmount).toString());
                stakedValue = new bigNumber(currentAmount).toString()
            } else {
                setStakedAmount(0.0);
                stakedValue = 0.0;
            }

            const pendingMTLX = await instance.amplifyContractInstance.methods
                // .pendingMTLX(parseInt(pool), props.user.address)
                .pendingMTLX(
                    isMigrated ? 1 : parseInt(pool),
                    props.user.address
                )
                .call();
            setCurrentValue(currentAmount);
            const mtlxPerBlock = await instance.amplifyContractInstance.methods
                .MTLXPerBlock()
                .call();
            const mtlxPrice = route.midPrice.toFixed(pool === "0" ? 3 : 6);
            setMtlxValue(mtlxPrice);
            const blocksPerDay = 4 * 60 * 24;
            let totalStacked = !isMigrated
                ? await instance.lpContractInstance.methods
                      .balanceOf(contractAddresses.amplify)
                      .call()
                : await instance.pancakePairContractInstance.methods
                      .balanceOf(contractAddresses.amplify)
                      .call();
            if (pool === "1") {
                totalStacked = await instance.lpethContractInstance.methods
                    .balanceOf(contractAddresses.amplify)
                    .call();
            }
            const last24HoursYield =
                parseFloat(mtlxPerBlock / 10 ** multiplier) *
                mtlxPrice *
                blocksPerDay *
                (stakedValue / parseFloat(totalStacked / 10 ** multiplier));
            const annualYield = last24HoursYield * 365;
            setAPY(truncateDecimals((annualYield / stakedValue) * 100, 2));
            setMTLX(
                new bigNumber(pendingMTLX).div(10 ** multiplier).toNumber()
            );
            let routeMTLX = route;
            if (pool === "1") {
                let usdtMtlxPair = null;
                if (!isMigrated) {
                    usdtMtlxPair = await FetcherBSC.fetchPairData(
                        usdtToken,
                        mtlxToken,
                        provider
                    );
                } else {
                    usdtMtlxPair = await FetcherV2.fetchPairData(
                        usdtToken,
                        mtlxToken,
                        provider
                    );
                }

                if (!isMigrated) {
                    routeMTLX = new RouteBSC([usdtMtlxPair], mtlxToken);
                } else {
                    routeMTLX = new RouteV2([usdtMtlxPair], mtlxToken);
                }
            }
            let mtlxRewardInUSDT = new bigNumber(pendingMTLX)
                .div(new bigNumber(10 ** multiplier))
                .multipliedBy(new bigNumber(routeMTLX.midPrice.toFixed(8)));
            let mtlxRewardsCollected = await getMTLXRewards(
                props.user.address,
                pool === "0"
                    ? props.networkID === 56
                        ? "BUSD"
                        : "USDT"
                    : "ETH",
                props.networkID
            );
            let mtlxRewardsCollectedUSDT = 0;
            if (!mtlxRewardsCollected) {
                mtlxRewardsCollected = 0;
            }
            setRewardsCollected(mtlxRewardsCollected);
            if (mtlxRewardsCollected !== 0) {
                mtlxRewardsCollectedUSDT = new bigNumber(mtlxRewardsCollected)
                    .multipliedBy(
                        new bigNumber(routeMTLX.midPrice.toSignificant(8))
                    )
                    .toNumber();
            }

            setRewardsCollectedUSDT(mtlxRewardsCollectedUSDT);
            setMTLXInUSDT(mtlxRewardInUSDT.toFixed(2));

            if (
                !parseFloat(stakedValue) ||
                // !stakedValue ||
                parseFloat(stakedValue) === 0 ||
                // stakedValue === 0 ||
                new bigNumber(currentAmount).eq(new bigNumber(0)) ||
                lpTokensStakedAmount === 0
            ) {
                setValueIncrease(0);
            } else {
                if (pool === "1") {
                    currentAmount = new bigNumber(currentAmount)
                        .div(route.midPrice.toSignificant(8))
                        .multipliedBy(routeMTLX.midPrice.toSignificant(8))
                        .toNumber();
                    stakedValue = new bigNumber(stakedValue)
                        .div(route.midPrice.toSignificant(8))
                        .multipliedBy(routeMTLX.midPrice.toSignificant(8))
                        .toNumber();
                    mtlxRewardsCollectedUSDT = new bigNumber(
                        mtlxRewardsCollected
                    )
                        .multipliedBy(
                            new bigNumber(routeMTLX.midPrice.toSignificant(8))
                        )
                        .toNumber();
                    mtlxRewardInUSDT = new bigNumber(pendingMTLX)
                        .div(new bigNumber(10 ** multiplier))
                        .multipliedBy(
                            new bigNumber(routeMTLX.midPrice.toFixed(8))
                        );
                }
                setValueIncrease(
                    (
                        ((parseFloat(currentAmount) +
                            mtlxRewardInUSDT.toNumber() +
                            mtlxRewardsCollectedUSDT -
                            parseFloat(stakedValue)) /
                            parseFloat(stakedValue)) *
                        100
                    ).toFixed(3)
                );
            }
            setRewardsCollectedUSDT(mtlxRewardsCollectedUSDT);
            setDate(moment().format("MMM DD YYYY hh:mm a"));
            setLPTokensStaked(
                new bigNumber(userInfo.amount)
                    .div(new bigNumber(10 ** 18))
                    .toNumber()
            );
        } catch (e) {
            console.log("Stake Card", e);
        }
    };

    const getPoolStatus = async () => {
        try {
            const userInfo = await instance.amplifyContractInstance.methods
                // .userInfo(pool, props.user.address)
                .userInfo(isMigrated ? 1 : pool, props.user.address)
                .call();
            let lpTokenBalanceAmplify = !isMigrated
                ? await instance.lpContractInstance.methods
                      .balanceOf(contractAddresses.amplify)
                      .call()
                : await instance.pancakePairContractInstance.methods
                      .balanceOf(contractAddresses.amplify)
                      .call();
            if (pool === "1") {
                lpTokenBalanceAmplify =
                    await instance.lpethContractInstance.methods
                        .balanceOf(contractAddresses.amplify)
                        .call();
            }

            let poolshare = (
                (parseFloat(userInfo.amount) / lpTokenBalanceAmplify) *
                100
            ).toFixed(2);
            setPoolShare(
                new bigNumber(lpTokenBalanceAmplify).eq(0) ? 0 : poolshare
            );

            const rewardsDistributed = await getMtlxLeft(props.networkID);
            const mtlxLeftToFarm =
                totalTokens -
                new bigNumber(rewardsDistributed)
                    .div(new bigNumber(10 ** multiplier))
                    .toNumber();
            setTokensLeftToFarm(mtlxLeftToFarm);
            setTokensPercent(
                100 - ((totalTokens - mtlxLeftToFarm) / totalTokens) * 100
            );
        } catch (e) {
            console.log("getPoolStatus", e);
        }
    };

    const getWalletBalance = async () => {
        try {
            const usdt = await instance.mrc20ContractInstance.methods
                .balanceOf(props.user.address)
                .call();
            const mtlxTokensAmount = await instance.mtlxContractInstance.methods
                .balanceOf(props.user.address)
                .call();
            let balance = new bigNumber(usdt)
                .div(
                    new bigNumber(
                        10 **
                            (props.networkID === "1"
                                ? usdtMultiplier
                                : multiplier)
                    )
                )
                .toString();
            if (pool === "1") {
                balance = new bigNumber(
                    await window.web3.eth.getBalance(props.user.address)
                )
                    .div(new bigNumber(10 ** multiplier))
                    .toString();
            }
            setMTLXBalance(
                truncateDecimals(
                    parseFloat(mtlxTokensAmount) / 10 ** multiplier,
                    2
                )
            );
            setUSDTBalance(balance);
        } catch (err) {
            console.log("getWalletBalance", err);
        }
    };

    const getLPTokens = async () => {
        try {
            let LPTokensAmount = 0;
            if (pool === "0") {
                LPTokensAmount = !isMigrated
                    ? await instance.lpContractInstance.methods
                          .balanceOf(props.user.address)
                          .call()
                    : await instance.pancakePairContractInstance.methods
                          .balanceOf(props.user.address)
                          .call();
            } else {
                LPTokensAmount = await instance.lpethContractInstance.methods
                    .balanceOf(props.user.address)
                    .call();
            }
            setLPTokens(
                new bigNumber(LPTokensAmount).div(10 ** multiplier).toString()
            );
        } catch (err) {
            console.log("getLPTokens", err);
        }
    };

    const updateAllBalances = async () => {
        setLoading(true);
        setLoadingText("Fetching balances...");
        await getStakeCardBalances();
        await getPoolStatus();
        await getWalletBalance();
        await getLPTokens();
        setLoadingText("Loading");
        setLoading(false);
    };

    const renderHeading = () => {
        return (
            <div className={classes.flexDiv}>
                <Grid item md={2}>
                    <Typography className={classes.heading} variant="h4">
                        Amplify
                    </Typography>
                </Grid>
                <Grid item md={8}>
                    <Typography
                        variant="subtitle1"
                        className={classes.addressText}
                        style={{
                            padding: 8,
                            textAlign: "center",
                            color: "white",
                            fontFamily:
                                '"GTWalsheimRegular", "Roboto", "Helvetica", "Arial", sans-serif',
                        }}
                    >
                        anyMTLX: {mtlxValue}{" "}
                        {pool === "0"
                            ? props.networkID === "1"
                                ? "USDT"
                                : "BUSD"
                            : "ETH"}
                    </Typography>
                </Grid>
                <Grid item md={2} style={{ textAlign: "end" }}>
                    <Migrate
                        setLoading={setLoading}
                        setLoadingText={setLoadingText}
                        updateStakedAmount={updateStakedAmount}
                        getStakedAmount={getStakedAmount}
                        getMTLXRewards={getMTLXRewards}
                        updateBalances={updateAllBalances}
                        isMigrated={isMigrated}
                        lpTokensStaked={lpTokensStaked}
                        lpTokens={lpTokens}
                    />
                </Grid>
            </div>
        );
    };

    const formatNumber = (num) => {
        return parseFloat(num)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatNumberwOutDecimal = (num) => {
        return parseFloat(num)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const renderStakeCard = () => (
        <Grid item xs={12}>
            <StakeCard
                stakedAmount={stakedAmount}
                currentValue={currentValue}
                valueIncrease={formatNumberwOutDecimal(valueIncrease)}
                getStakeCardBalance={getStakeCardBalances}
                APY={APY}
                mtlxAcc={formatNumber(mtlx)}
                mtlxAccInUSDT={formatNumber(mtlxInUSDT)}
                poolShare={formatNumber(poolShare)}
                lpTokensStaked={lpTokensStaked}
                currentDate={date}
                tokensLeftToFarm={tokensLeftToFarm}
                tokensPercent={tokensPercent}
                rewardsCollected={rewardsCollected}
                rewardsCollectedUSDT={rewardsCollectedUSDT}
                getPoolStatus={getPoolStatus}
                pool={pool}
                updateAllBalances={updateAllBalances}
                networkSelected={props.networkID}
            />
        </Grid>
    );

    const renderTokenStatusCard = () => (
        <Grid item xs={12}>
            <TokenStatusCard
                usdtBalance={formatNumber(usdtBalance)}
                mtlxBalance={formatNumber(mtlxBalance)}
                lpTokens={lpTokens}
                pool={pool}
                networkSelected={props.networkID}
            />
        </Grid>
    );

    const renderTransactioPanel = () => (
        <Grid style={{ padding: "16px 0 16px 16px" }} item xs={6}>
            <TransactionPanel
                // drizzle={props.drizzle}
                setLoading={setLoading}
                // drizzleState={props.drizzleState}
                updateBalances={updateAllBalances}
                setLoadingText={setLoadingText}
                stakedAmount={stakedAmount}
                usdtBalance={parseFloat(usdtBalance)}
                address={props.user.address}
                mtlxRewards={mtlx}
                updateStakedAmount={updateStakedAmount}
                lpTokensStaked={lpTokensStaked}
                lpTokens={lpTokens}
                getStakedAmount={getStakedAmount}
                getMTLXRewards={getMTLXRewards}
                mtlxValue={mtlxValue}
                pool={pool}
                networkSelected={props.networkID}
                lpContract={
                    !isMigrated
                        ? instance.lpContractInstance
                        : instance.pancakePairContractInstance
                }
                amplifyContract={instance.amplifyContractInstance}
                lpethContract={instance.lpethContractInstance}
                mrc20Contract={instance.mrc20ContractInstance}
                mtlxContract={instance.mtlxContractInstance}
                hrc20inContract={instance.hrc20inContractInstance}
                huniswapContract={!isMigrated ? instance.huniswapContractInstance : instance.hpancakeContractInstance}
                uniswapContract={instance.uniswapContractInstance}
                proxyContract={instance.proxyContractInstance}
                isMigrated={isMigrated}
            />
        </Grid>
    );

    const renderLPTokensTransaction = () => (
        <Grid item xs={6}>
            <LPTokensTransaction
                // drizzle={props.drizzle}
                address={props.user.address}
                updateBalances={updateAllBalances}
                setLoading={setLoading}
                setLoadingText={setLoadingText}
                stakedAmount={stakedAmount}
                lpTokens={lpTokens}
                mtlxRewards={mtlx}
                updateStakedAmount={updateStakedAmount}
                lpTokensStaked={lpTokensStaked}
                getStakedAmount={getStakedAmount}
                getMTLXRewards={getMTLXRewards}
                mtlxValue={mtlxValue}
                pool={pool}
                networkSelected={props.networkID}
                lpContract={
                    !isMigrated
                        ? instance.lpContractInstance
                        : instance.pancakePairContractInstance
                }
                amplifyContract={instance.amplifyContractInstance}
                lpethContract={instance.lpethContractInstance}
                mrc20Contract={instance.mrc20ContractInstance}
                mtlxContract={instance.mtlxContractInstance}
				isMigrated={isMigrated}
            />
        </Grid>
    );

    const renderBody = () => (
        <Container className={`${classes.content} content`}>
            {renderHeading()}
            {!isMigrated && (
                <Grid item md={12} style={{ textAlign: "center" }}>
                    <Typography variant="h6" style={{ color: "#ff6666" }}>
                        Please migrate all PancakeSwap V1 LP tokens by staking
                        them in Amplify and then pressing "Migrate".
                    </Typography>
                </Grid>
            )}
            <br />
            <Grid container spacing={4}>
                {renderStakeCard()}
            </Grid>
            <Grid container spacing={4}>
                {renderTransactioPanel()}
                {renderLPTokensTransaction()}
            </Grid>
            <Grid container spacing={4}>
                {renderTokenStatusCard()}
            </Grid>
        </Container>
    );

    const renderNetworkBackdrop = () => (
        <Backdrop className={classes.backdrop} open={props.networkID !== 56}>
            <Grid className={classes.gridStyle}>
                <img src={Refresh} alt="lock" width="20%" />
                <Typography className={classes.typographyBackdrop}>
                    Please connect to Binance Smart Chain and Refresh.
                </Typography>
                <CopyToClipboard
                    text="https://bsc-dataseed.binance.org/"
                    onCopy={onCopy}
                >
                    <Tooltip title={copied ? "Copied!" : "Click to copy"}>
                        <Typography
                            className={classes.typographyBackdrop}
                            style={{ cursor: "pointer" }}
                        >
                            RPC Url: https://bsc-dataseed.binance.org/{" "}
                        </Typography>
                    </Tooltip>
                </CopyToClipboard>
                <Typography className={classes.typographyBackdrop}>
                    Network Id: 56
                </Typography>
                <Typography className={classes.typographyBackdrop}>
                    Currency Symbol: BNB
                </Typography>
                <CopyToClipboard text="https://bscscan.com" onCopy={onCopy}>
                    <Tooltip title={copied ? "Copied!" : "Click to copy"}>
                        <Typography
                            className={classes.typographyBackdrop}
                            style={{ cursor: "pointer" }}
                        >
                            Explorer: https://bscscan.com
                        </Typography>
                    </Tooltip>
                </CopyToClipboard>
            </Grid>
        </Backdrop>
    );

    const renderLoadingBackdrop = () => (
        <Backdrop className={classes.backdrop} open={loading}>
            <Grid className={classes.gridStyle}>
                <img src={Loader} alt="lock" width="20%" />
                <Typography className={classes.typographyBackdropLoading}>
                    {loadingText}
                </Typography>
            </Grid>
        </Backdrop>
    );

    return (
        <>
            {props.networkID && renderNetworkBackdrop()}
            {loading && renderLoadingBackdrop()}
            <AppHeader
                address={props.user.address}
                network={props.networkID}
                networkSelected={props.networkID}
            />
            {renderBody()}
            <div id="background" />
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    network: state.user.network,
    networkID: state.user.networkID,
});

export default connect(mapStateToProps, { connectWallet })(App);
