import hrc20Mainnet from '../ABI/HERC20TOKENIN_ABI.json';
import hrc20BSC from '../ABI/HERC20TOKENIN_BSC_ABI.json';

class HRC20TokenIn {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(networkId === 1 ? hrc20Mainnet : hrc20BSC, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new HRC20TokenIn(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;