import React from 'react';
import useStyles from './index.style';
import { Typography, Grid, Button } from '@material-ui/core';

const UnstakePanel = (props) => {
  const classes = useStyles();
  return (
    <>
      <br />
      <div className={classes.buttonContainer}>
        <div style={{ display: 'flex' }}>
          <Grid item xs={6}>
            <Button
              className={classes.buttonStyle}
              onClick={props.claimMTLXRewards}
              disabled={props.mtlxRewards === 0}
            >Claim anyMTLX Rewards</Button>
          </Grid>
          <Grid item xs={6} style={{ marginLeft: 16 }}>
            <Button
              className={classes.redeemButtonStyle}
              onClick={props.unStakeLPTokens}
              disabled={props.lpTokensStaked === 0}
            >Exit: Claim and Unstake All</Button>
          </Grid>
        </div>
        <br />
        <Typography className={classes.linkText} >Please use this option to provide liquidity via PancakeSwap pool tokens once you have added liquidity to
      {props.pool === '0' ?
          (props.networkSelected === 1 ?
            <a className={classes.link} href="https://info.uniswap.org/pair/0x1a57ec5459928389fbb5612ff2a5e0b534fd9e2e" target="_blank" rel="noopener noreferrer">
              &nbsp;MTLX-USDT </a>
            :
            <a className={classes.link} href="https://pancakeswap.info/pair/0x02B0B1229A918815E872c1E4D4c27d1DF8290C90" target="_blank" rel="noopener noreferrer">
              &nbsp;anyMTLX-BUSD </a>
          )
          :
          <a className={classes.link} href="https://info.uniswap.org/pair/0xa71d014ffc0d2aff34d1fe1672dcdef6ec00af66" target="_blank" rel="noopener noreferrer">
            &nbsp;MTLX-ETH </a>
        } pool</Typography>
      </div>
    </>
  );
};

export default UnstakePanel;