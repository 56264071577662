import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomizedSlider from '../Slider';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    background: '#222330',
    display: 'flex',
    padding: 0,
  },
  grid: {
    width: '100%',
    justifyContent: 'space-between',
    color: 'white'
  },
  slider: {
    width: '90%',
    marginLeft: '5%',
    marginBottom: '3.2%'
  },
  floorTypography: {
    // width: '12%',
    // flex: 2,
    color: '#7C7C7D',
    fontSize: 14,
    margin: 'auto',
  },
  capTypography: {
    // width: '12%',
    // flex: 2,
    color: '#7C7C7D',
    textAlign: 'right',
    margin: 'auto',
    marginRight: 0,
    fontSize: 14
  },
  floorCapText: {
    color: 'white !important'
  },
  barGrid: {
    display: 'flex',
    margin: '0',
    padding: '10px 20px',
    alignItems: 'center',
    background: '#222330'
  },
  typography: {
    textAlign: "end",
    color: 'white'
  },
  background: {
    backgroundColor: '#0e0e15'
  },
  subHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: "8px 0",
    color: 'white'
  },
  item: {
    textAlign: 'left',
    position: 'relative',
    padding: '16px 20px',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: 1,
      background: '#0e0e15',
      height: '100%',
    }
  },
  itemGreyText: {
    fontSize: 12,
    color: '#9098AC',
  },
  itemWhiteText: {
    fontSize: 12,
    color: 'white',
  },
}));

const Slider = ({ price, value }) => {
  const classes = useStyles();
  const [thumb, setThumb] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [max, setMax] = useState(100);
  const [min, setMin] = useState(0);
  const [label, setLabel] = useState('0K')

  useEffect(() => {
    const diff = parseFloat(100) - parseFloat(0);
    if (diff) {
      const buffer = diff / 10
      setBuffer(buffer)
      const max = parseFloat(100)
      setMax(max)
      const min = parseFloat(0)
      setMin(min)
    }
    setLabel(value)
    setThumb(parseFloat(price))
  }, [price, value])

  return (
    <Grid item className={classes.barGrid}>
      {thumb && <> <Typography component={'span'} className={classes.floorTypography}>0K</Typography>
        <CustomizedSlider max={max} min={min} step={buffer} value={thumb} label={label} />
        <Typography component={'span'} className={classes.capTypography}>385K</Typography> </>
      }
    </Grid>
  )
}

export default Slider;
