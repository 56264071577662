import { put, call, takeLatest } from 'redux-saga/effects';
import { updateMetaMask } from '../reducer/metaMask';
import { getAccountAddress, initializeWeb3, isMetamaskInstalled, askPermission, initializeTorusWallet } from '../utils/index';
import { updateAccount } from '../reducer/user';

function* startSaga() {
  yield takeLatest("FETCH_DATA", startupSaga);
  yield takeLatest("CONNECT_WALLET", connectWallet);
  yield call(connectWallet);
}

function* connectWallet(action) {
  try {
    const isMetamask = yield isMetamaskInstalled();
    // yield put(updateMetaMask({ isAvailable: isMetamask }))
    // initializeWeb3()
    // yield askPermission()
    // yield call(startupSaga)

    yield put(updateMetaMask({ isAvailable: isMetamask }))
    if (isMetamask && action.payload.wallet === "Metamask") {
      initializeWeb3()
      yield askPermission()
      yield call(startupSaga)

    } else if (action.payload.wallet === "Torus") {
      const isWalletConnected = yield initializeTorusWallet(action.payload.network)
      if (isWalletConnected) {
        yield call(startupSaga)
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* startupSaga() {
  try {
    yield put({ type: "NETWORK" })
    const address = yield getAccountAddress();
    yield put(updateAccount(address))
  } catch (error) {
    console.log(error)
  }
};

export default startSaga;
