import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { TokenAmount, Token, Fetcher, ChainId } from "@uniswap/sdk";
import { TokenAmount as TokenAmountBSC, Token as TokenBSC, Fetcher as FetcherBSC, ChainId as ChainIdBSC } from "@pancakeswap-libs/sdk";
import {
  TokenAmount as TokenAmountV2,
  Token as TokenV2,
  Fetcher as FetcherV2,
  ChainId as ChainIdV2,
} from "@pancakeswap-libs/sdk-v2";
import BigNumber from 'bignumber.js';
import { multiplier, contractAddressesObj, usdtMultiplier, slippage, gasFee, estimatedGas } from '../../constants';
import FarmPanel from '../../component/FarmPanel';
import RedeemPanel from '../../component/RedeemPanel';
import useStyles from './index.style';
import { ethers } from 'ethers';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function TabPanel(props) {
  const classes = useStyles()
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const TransactionPanel = (props) => {
  const [stakeInput, setStakeInput] = useState('');
  const [errorText, setErrorText] = useState('');
  const [maxSelected, setMaxSelected] = useState(false);
  const [tab, setTab] = useState(0);
  const tabsTitle = ["Farm", "Redeem"]
  const classes = useStyles();
  const contractAddresses = contractAddressesObj[56];

  let provider = null;
  if (props.networkSelected === 56) {
    provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
  } else {
    provider = new ethers.providers.InfuraProvider("mainnet", process.env.REACT_APP_INFURA_PROVIDER);
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const setMaxStakeInput = () => {
    setMaxSelected(true);
    setStakeInput(props.usdtBalance);
  };

  const onStakeInputChange = (event) => {
    setErrorText('');
    setMaxSelected(false);
    setStakeInput(event.target.value);
  };

  const stake = async () => {

    if (stakeInput === '') {
      setErrorText('Please enter an amount');
      return;
    }

    if (parseFloat(stakeInput) <= 0) {
      setErrorText('Amount can not be zero please enter valid amount');
      return;
    }

    if (parseFloat(stakeInput) > props.usdtBalance) {
      setErrorText(`Insufficient wallet balance (${props.usdtBalance})`);
      return;
    }

    if (maxSelected && parseFloat(stakeInput) <= estimatedGas) {
      setErrorText(`Wallet balance should be greater than ${estimatedGas}`);
      return;
    }

    try {
      props.setLoading(true);
      if (props.pool === '0')
        props.setLoadingText(`Approving ${props.networkSelected === 1 ? 'USDT' : 'BUSD'}...`);
      else
        props.setLoadingText('Approving ETH...');

      let usdtBeforeStaking = await props.mrc20Contract.methods.balanceOf(props.address).call();
      if (props.pool === '1') {
        usdtBeforeStaking = await window.web3.eth.getBalance(props.address);
      }

      const address = props.address;
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(1, address).call();
      // const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(props.pool, address).call();
      // const contracts = props.drizzle.contracts;
      let stake = '' + stakeInput;
      const index = stake.indexOf('.');
      if (index >= 0) {
        if (props.pool === '0' && props.networkSelected !== 1) {
          stake = stake.substr(0, index + 6);
        } else {
          stake = stake.substr(0, index + 18);
        }
      }
      let amount = 0;
      let usdtToSupply = 0;

      if (props.pool === '1') {
        if (maxSelected) {
          amount = window.web3.utils.toBN(new BigNumber(stake).minus(estimatedGas).multipliedBy(new BigNumber(10).pow(multiplier)));
          usdtToSupply = window.web3.utils.toBN(window.web3.utils.toWei(new BigNumber(stake).minus(estimatedGas).toString())).div(window.web3.utils.toBN('2'))
        } else {
          amount = window.web3.utils.toBN(new BigNumber(stake).multipliedBy(new BigNumber(10).pow(multiplier)));
          usdtToSupply = window.web3.utils.toBN(window.web3.utils.toWei(stake.toString())).div(window.web3.utils.toBN('2'))
        }
      } else {
        amount = window.web3.utils.toBN(new BigNumber(stake).multipliedBy(new BigNumber(10).pow(props.networkSelected === 1 ? usdtMultiplier : multiplier)));
        usdtToSupply = window.web3.utils.toBN(new BigNumber(stake).div(2).multipliedBy(new BigNumber(10).pow(props.networkSelected === 1 ? usdtMultiplier : multiplier)));
      }

      if (index >= 0) {
        if (props.pool === '0' && props.networkSelected !== 1) {
          stake = stake.substr(0, index + 4);
        } else {
          stake = stake.substr(0, index + 16);
        }
      }

      let slippageTolerance = 0;
      if (props.pool === '0') {
        slippageTolerance = window.web3.utils.toBN(new BigNumber(stake).minus(new BigNumber(stake).multipliedBy(0.01)).multipliedBy(new BigNumber(10).pow((props.networkSelected === 1 ? usdtMultiplier : multiplier) - 1)));
      } else {
        if (maxSelected) {
          slippageTolerance = window.web3.utils.toBN(new BigNumber(stake).minus(estimatedGas).minus(new BigNumber(stake).multipliedBy(0.01)).multipliedBy(new BigNumber(10).pow(multiplier - 1)));
        } else {
          slippageTolerance = window.web3.utils.toBN(new BigNumber(stake).minus(new BigNumber(stake).minus(0.1).multipliedBy(0.01)).multipliedBy(new BigNumber(10).pow(multiplier - 1)));
        }
      }

      const allowance = await props.mrc20Contract.methods.allowance(address, contractAddresses.proxy).call();
      const condition0 = new BigNumber(allowance).div(new BigNumber(10 ** (props.networkSelected === 1 ? usdtMultiplier : multiplier))).eq(new BigNumber(0));

      if (props.pool === '0')
        if (!condition0) {
          const conditionAmount = new BigNumber(amount.toString()).isGreaterThan(new BigNumber(allowance));
          if (conditionAmount) {
            props.setLoadingText(`Insufficient amount already approved. Resetting approval on ${props.networkSelected === 1 ? 'USDT' : 'BUSD'} contract...`);
            await props.mrc20Contract.methods.approve(contractAddresses.proxy, 0).send({ from: address });
            props.setLoadingText(`Approving ${props.networkSelected === 1 ? 'USDT' : 'BUSD'}...`);
            await props.mrc20Contract.methods.approve(contractAddresses.proxy, amount).send({ from: address });
          }
        } else {
          await props.mrc20Contract.methods.approve(contractAddresses.proxy, amount).send({ from: address });
        }

      let originalUserBalance;
      if (props.pool === '0')
        originalUserBalance = await props.lpContract.methods.balanceOf(address).call();
      else
        originalUserBalance = await props.lpethContract.methods.balanceOf(address).call();

      // await contracts.MockERC20.methods.approve(contractAddresses.proxy, amount).send({ from: address, gasFee: gasFee });

      if (props.pool === '0') {
        props.setLoadingText(`Swapping ${props.networkSelected === 1 ? 'USDT' : 'BUSD'} for MTLX and supplying ${props.networkSelected === 1 ? 'USDT-MTLX' : 'BUSD-MTLX'} liquidity...`);
      } else {
        props.setLoadingText('Swapping ETH for MTLX and supplying ETH-MTLX liquidity...');
      }
      let tokenInData = await props.hrc20inContract.methods.inject([contractAddresses.usdt], [amount]).encodeABI();
      if (props.pool === '1') {
        tokenInData = await props.hrc20inContract.methods.inject([contractAddresses.eth], [amount]).encodeABI();
      }
      const minMTLXExpected = await props.uniswapContract.methods.getAmountsOut(
        usdtToSupply,
        [props.pool === '0' ? contractAddresses.usdt : contractAddresses.eth, contractAddresses.mtlx]
      ).call();

      const bigMTLXExpected = window.web3.utils.toBN(minMTLXExpected[1]);

      let uniswap_supply_data, uniswap_swap_data;
      if (props.pool === '0') {
        uniswap_swap_data = props.huniswapContract.methods.swapExactTokensForTokens(
          usdtToSupply,
          bigMTLXExpected,
          [contractAddresses.usdt, contractAddresses.mtlx]
        ).encodeABI();

        if (props.networkSelected === 56) {
          uniswap_supply_data = props.huniswapContract.methods.addLiquidity(
            contractAddresses.usdt,
            contractAddresses.mtlx,
            usdtToSupply,
            bigMTLXExpected,
            '1',
            '1',
          ).encodeABI();
        }
        else
          uniswap_supply_data = props.huniswapContract.methods.addLiquidity(
            contractAddresses.usdt,
            contractAddresses.mtlx,
            usdtToSupply,
            bigMTLXExpected,
            slippageTolerance,
            slippageTolerance,
          ).encodeABI();

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HERC20TokensIn, !props.isMigrated ? contractAddresses.HUniswapV2 : contractAddresses.hpancake , !props.isMigrated ? contractAddresses.HUniswapV2 : contractAddresses.hpancake],
          [tokenInData, uniswap_swap_data, uniswap_supply_data]
        ).send({ from: address });
      }
      else {
        uniswap_swap_data = props.huniswapContract.methods.swapExactETHForTokens(
          usdtToSupply,
          window.web3.utils.toBN(bigMTLXExpected).muln(0.96),
          [contractAddresses.eth, contractAddresses.mtlx]
        ).encodeABI();

        uniswap_supply_data = props.huniswapContract.methods.addLiquidityETH(usdtToSupply,
          contractAddresses.mtlx, bigMTLXExpected, '1', '1').encodeABI()

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HUniswapV2, contractAddresses.HUniswapV2],
          [uniswap_swap_data, uniswap_supply_data]
        ).send({ from: address, value: amount });
      }

      props.setLoadingText(`Almost there! Approving ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} LP tokens to Amplify...`);
      let userBalance;
      if (props.pool === '0')
        userBalance = await props.lpContract.methods.balanceOf(address).call();
      else
        userBalance = await props.lpethContract.methods.balanceOf(address).call();

      const userBalanceToStake = window.web3.utils.toBN(userBalance).sub(window.web3.utils.toBN(originalUserBalance));
      // const userBalanceToStake = window.web3.utils.toBN(new BigNumber(userBalance).div(10 ** multiplier).minus(new BigNumber(originalUserBalance).div(10 ** multiplier)).multipliedBy(10 ** multiplier).toNumber());

      if (props.pool === '0')
        await props.lpContract.methods.approve(
          contractAddresses.amplify,
          userBalanceToStake
        ).send({ from: address });
      else
        await props.lpethContract.methods.approve(
          contractAddresses.amplify,
          userBalanceToStake
        ).send({ from: address });

      let loadingText = `Depositing ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} LP tokens in Amplify.`;
      if (props.mtlxRewards > 0) {
        loadingText += ' This will also claim the MTLX rewards accumulated to your wallet.'
      }
      loadingText += '..';
      props.setLoadingText(loadingText);
      await props.amplifyContract.methods.deposit(1, userBalanceToStake).send({ from: address });
      // await props.amplifyContract.methods.deposit(props.pool, userBalanceToStake).send({ from: address });

      setStakeInput('');
      let usdtAfterStaking, diff;
      if (props.pool === '0') {
        usdtAfterStaking = await props.mrc20Contract.methods.balanceOf(props.address).call();
        diff = new BigNumber(usdtBeforeStaking).minus(new BigNumber(usdtAfterStaking)).div(10 ** (props.networkSelected === 1 ? usdtMultiplier : multiplier)).toString();
      } else {
        usdtAfterStaking = await window.web3.eth.getBalance(props.address);
        diff = new BigNumber(usdtBeforeStaking).minus(new BigNumber(usdtAfterStaking)).div(10 ** multiplier).toString();
      }
      let mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();

      await props.updateStakedAmount(address, parseFloat(diff), mtlxReward, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
  };

  const claimMTLXRewards = async () => {
    try {
      props.setLoading(true);
      const address = props.address;
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(1, address).call();
      // const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(props.pool, address).call();
      await props.amplifyContract.methods.withdraw(1, 0).send({ from: address });
      // await props.amplifyContract.methods.withdraw(props.pool, 0).send({ from: address });
      const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      await props.updateStakedAmount(address, 0, mtlxReward, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);
      props.updateBalances();
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoading(false);
    }
  };

  const unstakeETH = async () => {
    try {
      props.setLoading(true);
      props.setLoadingText(`Withdrawing ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} LP tokens from Amplify...`);
      const address = props.address;
      const userInfo = await props.amplifyContract.methods.userInfo(1, address).call();
      // const pendingMTLX = await props.drizzle.contracts.Amplify.methods.pendingMTLX(1, address).call();
      await props.amplifyContract.methods.withdraw(1, window.web3.utils.toBN(userInfo.amount)).send({ from: address });
      const savedStakedAmount = await props.getStakedAmount(address, 'ETH', props.networkSelected);
      // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      const rewards = await props.getMTLXRewards(address, 'ETH', props.networkSelected);
      await props.updateStakedAmount(address, savedStakedAmount * (-1), rewards * (-1), 'ETH', props.networkSelected);

      props.setLoadingText(`Approving ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} LP token to redeem ...`);
      const lpTokenWithdrawn = await props.lpethContract.methods.balanceOf(address).call();
      await props.lpethContract.methods.approve(contractAddresses.proxy, window.web3.utils.toBN(lpTokenWithdrawn)).send({ from: address });
      const tokenInData = await props.hrc20inContract.methods.inject(
        [contractAddresses.lpETH],
        [window.web3.utils.toBN(lpTokenWithdrawn)]
      ).encodeABI();

      props.setLoadingText(`Redeeming ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} tokens and Swapping MTLX for BUSD...`);

      const ethToken = new Token(
        ChainId.MAINNET,
        contractAddresses.eth,
        multiplier,
        "WETH",
        "WrappedETH"
      );

      const mtlxToken = new Token(
        ChainId.MAINNET,
        contractAddresses.mtlx,
        multiplier,
        "MTLX",
        "Mettalex"
      );

      Fetcher.fetchPairData(ethToken, mtlxToken, provider).then(async (pair) => {
        const lpTokensTotalSupply = await props.lpethContract.methods.totalSupply().call();
        const kLast = await props.lpethContract.methods.kLast().call();
        const lpToken = new Token(
          ChainId.MAINNET,
          contractAddresses.lpETH,
          multiplier,
          await props.lpethContract.methods.symbol().call(),
          await props.lpethContract.methods.name().call()
        );
        const ethValue = pair.getLiquidityValue(
          ethToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        const mtlxValue = pair.getLiquidityValue(
          mtlxToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        const mtlxRedeemed = window.web3.utils.toBN(new BigNumber(mtlxValue.toExact()).multipliedBy(10 ** multiplier)).muln(0.96);
        const minETHOut = window.web3.utils.toBN(new BigNumber(ethValue.toExact()).multipliedBy(10 ** multiplier)).muln(0.96);

        const mtlxToETHExpected = await props.uniswapContract.methods.getAmountsOut(
          mtlxRedeemed,
          [contractAddresses.mtlx, contractAddresses.eth]
        ).call();
        const minMTLXToETH = window.web3.utils.toBN(mtlxToETHExpected[1]).muln(0.96);

        const uni_redeem_tx = await props.huniswapContract.methods.removeLiquidityETH(contractAddresses.mtlx, window.web3.utils.toBN(lpTokenWithdrawn), mtlxRedeemed, minETHOut).encodeABI()

        const uni_mtlx_swap = await props.huniswapContract.methods.swapExactTokensForETH(mtlxRedeemed, minMTLXToETH, [contractAddresses.mtlx, contractAddresses.eth]).encodeABI()

        await props.proxyContract.methods.batchExec([contractAddresses.HERC20TokensIn, contractAddresses.HUniswapV2, contractAddresses.HUniswapV2], [tokenInData, uni_redeem_tx, uni_mtlx_swap]).send({ from: address })

        // const savedStakedAmount = await props.getStakedAmount(address);
        // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
        // props.updateStakedAmount(address, savedStakedAmount * (-1), mtlxReward);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      }).catch(err => {
        console.log(err);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      });

    }
    catch (err) {
      console.log(err);
      const address = props.address;
      const savedStakedAmount = await props.getStakedAmount(address);
      props.updateStakedAmount(address, savedStakedAmount * (-1));
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
  };

  const unstakeUSDT = async () => {
    try {
      props.setLoading(true);
      props.setLoadingText(`Withdrawing ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} LP tokens from Amplify...`);
      
      const address = props.address;
      const userInfo = await props.amplifyContract.methods.userInfo(!props.isMigrated ? 0 : 1, address).call();
      // const pendingMTLX = await props.drizzle.contracts.Amplify.methods.pendingMTLX(0, address).call();

      //Get all acc rewards and store in pendingMTLX var
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(1, address).call();
      //Convert pendingMTLX using BigNumber, store in MTLXreward
      const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      //Update CF
      await props.updateStakedAmount(address, 0, mtlxReward, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);

      let prelpTokenWithdrawn = await props.lpContract.methods.balanceOf(address).call();

      await props.amplifyContract.methods.withdraw(!props.isMigrated ? 0 : 1, window.web3.utils.toBN(userInfo.amount)).send({ from: address });
      const savedStakedAmount = await props.getStakedAmount(address, props.networkSelected === 56 ? 'BUSD' : 'USDT', props.networkSelected);
      // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      const rewards = await props.getMTLXRewards(address, props.networkSelected === 56 ? 'BUSD' : 'USDT', props.networkSelected);
      await props.updateStakedAmount(address, savedStakedAmount * (-1), rewards * (-1), props.networkSelected === 56 ? 'BUSD' : 'USDT', props.networkSelected);

      props.setLoadingText(`Approving ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} LP token to redeem ...`);
      let postlpTokenWithdrawn = await props.lpContract.methods.balanceOf(address).call();
      let lpTokenWithdrawn = window.web3.utils.toBN(postlpTokenWithdrawn).sub(window.web3.utils.toBN(prelpTokenWithdrawn));
      await props.lpContract.methods.approve(contractAddresses.proxy, window.web3.utils.toBN(lpTokenWithdrawn)).send({ from: address });

      let tokenInData = await props.hrc20inContract.methods.inject(
        [!props.isMigrated ? contractAddresses.lp : contractAddresses.lpV2],
        [window.web3.utils.toBN(lpTokenWithdrawn)]
      ).encodeABI();

      props.setLoadingText(`Redeeming ${props.networkSelected === 1 ? 'Uni V2' : 'Pancake'} tokens and Swapping MTLX for BUSD...`);

      let usdtToken = null;
      let mtlxToken = null;

      if (!props.isMigrated) {
        usdtToken = new TokenBSC(
          ChainIdBSC.MAINNET,
          contractAddresses.usdt,
          multiplier,
          "BUSD",
          "BUSD Token"
        );

        mtlxToken = new TokenBSC(
          ChainIdBSC.MAINNET,
          contractAddresses.mtlx,
          multiplier,
          "MTLX",
          "MTLX-ERC20"
        );
      } else {
        usdtToken = new TokenV2(
          ChainIdV2.MAINNET,
          contractAddresses.usdt,
          multiplier,
          "BUSD",
          "BUSD Token"
        );

        mtlxToken = new TokenV2(
          ChainIdV2.MAINNET,
          contractAddresses.mtlx,
          multiplier,
          "MTLX",
          "Mettalex"
        );
      }

      try {
        let pair = null;
        if (!props.isMigrated) {
          pair = await FetcherBSC.fetchPairData(usdtToken, mtlxToken, provider);
        } else {
          pair = await FetcherV2.fetchPairData(usdtToken, mtlxToken, provider);
        }

        const lpTokensTotalSupply = await props.lpContract.methods.totalSupply().call();
        const kLast = await props.lpContract.methods.kLast().call();

        let lpToken = null;
        let usdtValue = null;
        let mtlxValue = null;

        if (!props.isMigrated) {
          lpToken = new TokenBSC(
            ChainIdBSC.MAINNET,
            contractAddresses.lp,
            multiplier,
            await props.lpContract.methods.symbol().call(),
            await props.lpContract.methods.name().call()
          );
          usdtValue = pair.getLiquidityValue(
            usdtToken,
            new TokenAmountBSC(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
            new TokenAmountBSC(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
            true,
            kLast
          );

          mtlxValue = pair.getLiquidityValue(
            mtlxToken,
            new TokenAmountBSC(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
            new TokenAmountBSC(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
            true,
            kLast
          );
        } else {
          lpToken = new TokenV2(
            ChainIdV2.MAINNET,
            contractAddresses.lpV2,
            multiplier,
            await props.lpContract.methods.symbol().call(),
            await props.lpContract.methods.name().call()
          );
          usdtValue = pair.getLiquidityValue(
            usdtToken,
            new TokenAmountV2(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
            new TokenAmountV2(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
            true,
            kLast
          );

          mtlxValue = pair.getLiquidityValue(
            mtlxToken,
            new TokenAmountV2(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
            new TokenAmountV2(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
            true,
            kLast
          );
        }

        let mtlxRedeemed, minUSDTOut;

        mtlxRedeemed = window.web3.utils.toBN(new BigNumber(mtlxValue.toExact()).multipliedBy(10 ** multiplier)).muln(slippage);
        minUSDTOut = window.web3.utils.toBN(new BigNumber(usdtValue.toExact()).multipliedBy(10 ** (props.networkSelected === 1 ? usdtMultiplier : multiplier))).muln(slippage);
        const uniRedeemTx = await props.huniswapContract.methods.removeLiquidity(
          contractAddresses.usdt,
          contractAddresses.mtlx,
          window.web3.utils.toBN(lpTokenWithdrawn),
          minUSDTOut,
          mtlxRedeemed
        ).encodeABI();

        const mtlxToUSDTExpected = await props.uniswapContract.methods.getAmountsOut(
          mtlxRedeemed,
          [contractAddresses.mtlx, contractAddresses.usdt]
        ).call();

        const minMTLXToUSDT = window.web3.utils.toBN(mtlxToUSDTExpected[1]).muln(slippage);

        const UniSwapMtlx = await props.huniswapContract.methods.swapExactTokensForTokens(
          mtlxRedeemed,
          minMTLXToUSDT,
          [contractAddresses.mtlx, contractAddresses.usdt]
        ).encodeABI();

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HERC20TokensIn, !props.isMigrated ? contractAddresses.HUniswapV2 : contractAddresses.hpancake, !props.isMigrated ? contractAddresses.HUniswapV2 : contractAddresses.hpancake],
          [tokenInData, uniRedeemTx, UniSwapMtlx]
        ).send({ from: address, gasFee: gasFee });

        // const savedStakedAmount = await props.getStakedAmount(address);
        // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
        // props.updateStakedAmount(address, savedStakedAmount * (-1), mtlxReward);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      }
      catch (err) {
        console.log(err);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      }

    }
    catch (err) {
      console.log(err);
      const address = props.address;
      const savedStakedAmount = await props.getStakedAmount(address);
      await props.updateStakedAmount(address, savedStakedAmount * (-1), 0, props.networkSelected === 56 ? 'BUSD' : 'USDT');
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
  };

  return (
    <CardContent className={classes.cardContent}>
        <Tabs TabIndicatorProps={{
          style: {
            backgroundColor: "#68dbda"
          }
        }} value={tab} onChange={handleChange} className={classes.tabs} >
          <Tab className={tab === 0 ? classes.tab : classes.disabledTab} label={
            tabsTitle[0]
          } />
          <Tab className={tab === 1 ? classes.tab : classes.disabledTab} label={
            tabsTitle[1]
          } />
          <Tooltip
            style={{ display: "inline", marginRight: "5px" }}
          >
            <Typography className={classes.icon}>{props.pool === '0' ? (props.networkSelected === 1 ? 'USDT' : 'BUSD') : 'ETH'}</Typography>
          </Tooltip>
        </Tabs>
        <TabPanel value={tab} index={0} className={`${classes.tabContainer} tabs`}>
          <FarmPanel
            setMaxStakeInput={setMaxStakeInput}
            stake={stake}
            onStakeInputChange={onStakeInputChange}
            value={stakeInput}
            errorText={errorText}
            pool={props.pool}
            networkSelected={props.networkSelected}
            lpTokensStaked={props.lpTokensStaked}
            lpTokens={props.lpTokens}
            isMigrated={props.isMigrated}
          />
        </TabPanel>
        <TabPanel value={tab} index={1} className={`${classes.tabContainer} tabs`}>
          <RedeemPanel
            unstakeUSDT={props.pool === '0' ? unstakeUSDT : unstakeETH}
            stakedAmount={props.stakedAmount}
            claimMTLXRewards={claimMTLXRewards}
            mtlxRewards={props.mtlxRewards}
            lpTokensStaked={props.lpTokensStaked}
            pool={props.pool}
            networkSelected={props.networkSelected}
            lpTokens={props.lpTokens}
            isMigrated={props.isMigrated}
          />
        </TabPanel>
      </CardContent>
  );
};

export default TransactionPanel;