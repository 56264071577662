import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flexBox: {
    display: 'flex',
  },
  cardTitle: {
    color: 'white',
    fontSize: '16px',
    fontFamily: "GTWalsheimMedium",
  },
  cardSubTitle: {
    color: '#9098AC',
    fontSize: '16px',
    fontFamily: "GTWalsheimRegular",
  },
  daysText: {
    color: '#D6D6D9'
  },
  active: {
    color: '#5643CC'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '10px'
  },
  contentText: {
    color: '#7C7C7D',
    fontSize: '14px',
  },

  incrementStyle: {
    color: "white",
    fontSize: '14px',
  },
  grid: {
    background: '#222330',
    display: "flex",
    justifyContent: "space-between",
    padding: "9px 20px 10px",
    marginTop: 16,
  },
  mtlxBox: {
    border: '1px solid #F97E5D',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 36px rgba(249, 126, 93, 0.4)',
  }
}));

export default useStyles;