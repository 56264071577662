import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    marginTop: 8,
  },
  grid: {
    display: "flex",
    padding: "18px 0",
    alignItems: "center",
  },
  header: {
    backgroundColor: "#2d2e3d ",
    position: "relative",
    zIndex: 999,
    padding: 0,
  },
  addressBox: {
    padding: "6px 16px",
    border: "1px solid #4A4D5F",
    boxSizing: "border-box",
    display: "flex",
  },
  addressText: {
    padding: "5px 0",
    color: "white",
  },
  versionText: {
    paddingRight: "20px",
    color: "#9A8EE0",
  },
  networkPaper: {
    padding: "12px 48px",
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "0%",
    background: "#68DBDA",
    textAlign: "center",
    fontWeight: "bold",
  },
  networkText: {
    padding: "4px 15px",
  },
  flexDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
}));

export default useStyles;
