import React, { useState } from 'react';
import {
  Container, Grid, Typography, Box, Tooltip,
} from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import Logo from '../../assets/logo.svg';
import Circle from '../../assets/circle.svg';
import { networkNames } from '../../constants';
import useStyles from './index.style';

const AppHeader = (props) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const onImageClick = () => {
    window.open('https://mettalex.com/');
  };

  const onCopy = () => {
    if (!copied) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  const formatAddress = () => {
    if (!props.address) { return '...................'; }

    const l = props.address.length;
    return (
      <CopyToClipboard text={props.address} onCopy={onCopy}>
        <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
          <Typography className={classes.accountNumberText}>
            {props.address.slice(0, 8)}
            ...
            {props.address.slice(l - 4, l)}
          </Typography>
        </Tooltip>
      </CopyToClipboard>
    )
  };
  return (
    <div className={classes.header}>
    <Container style={{ padding: 0 }}>
      <Grid className={classes.grid}>
        <Grid item xs={6}>
          <img src={Logo} className={classes.logo} alt="logo" onClick={onImageClick} />
        </Grid>
        <Grid item xs={6} className={classes.flexDiv}>
          <Box className={classes.addressBox}>
            <img src={Circle} alt='icon' />&nbsp;&nbsp;
            <Typography className={classes.addressText}>{formatAddress()}</Typography>
          </Box>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Box className={classes.networkPaper}>
            <Typography>{networkNames[props.network] || '........'}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </div>
  );
};

export default AppHeader;