import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import useStyles from './index.style';
import CssTextField from './CssTextField';

const FarmPanel = (props) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.textStyle}>Amount to farm ({props.pool === '0' ? (props.networkSelected === 1 ? 'USDT' : 'BUSD') : 'ETH'})</Typography>
      <div style={{ display: 'flex' }}>
        <Grid item xs={9}>
          <CssTextField
            color="primary"
            placeholder="Enter Amount..."
            type="number"
            fullWidth
            value={props.value}
            onChange={props.onStakeInputChange}
            InputProps={{
              endAdornment: (
                <Button
                  className={classes.maxButton}
                  onClick={props.setMaxStakeInput}
                >Max</Button>
              ),
              className: classes.input,
            }}
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item xs={3}>
          <Button
            // variant="contained"
            className={classes.stakeButtonDiv}
            onClick={props.stake}
            disabled={!props.isMigrated}
          >Farm</Button>
        </Grid>
      </div>
      <Typography className={classes.errorText}>{props.errorText}</Typography>
      <br />
      <div>
        <Typography style={{ textAlign: 'center' }} className={classes.textStyle}>Please use this option to provide liquidity using {props.pool === '0' ? (props.networkSelected === 1 ? 'USDT' : 'BUSD') : 'ETH'} only</Typography>
      </div>
      <br />
    </>
  );
};

export default FarmPanel;
