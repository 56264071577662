import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import BigNumber from 'bignumber.js';
import ContractInitialization from "../../utils/contractInitialize";
import { getAccountAddress } from "../../utils/index";
import { contractAddressesObj, gasFee } from "../../constants";

const useStyles = makeStyles(() => ({
    ButtonUi: {
        textTransform: "none",
        padding: "2px 30px",
        border: "1px solid #4A4D5F",
        color: "white",
        fontSize: "1rem",
        "&:hover": {
            opacity: "0.7",
        },
        fontFamily: "GTWalsheimLight",
        fontWeight: 300,
    },
}));

const Migrate = (props) => {
    const classes = useStyles();
    const { setLoadingText, setLoading, isMigrated, lpTokensStaked, lpTokens } = props;
    const contractAddresses = contractAddressesObj[56];

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoadingText("Withdrawing PancakeSwap V1 LP tokens from Amplify...");
        const address = await getAccountAddress();
        const savedStakedAmount = await props.getStakedAmount(
            address,
            "BUSD",
            56
        );
        const rewards = await props.getMTLXRewards(address, "BUSD", 56);

        try {
            const instances = await ContractInitialization(56);

            const userInfo = await instances.amplifyContractInstance.methods
                .userInfo(0, address)
                .call();
            
            const oldLPBalance = await instances.lpContractInstance.methods
                .balanceOf(address)
                .call();

            await instances.amplifyContractInstance.methods
                .withdraw(0, window.web3.utils.toBN(userInfo.amount))
                .send({ from: address, gasFee: gasFee });

            setLoadingText("Approving PancakeSwap V1 LP token management by migration contract...");

            const newLPBalance = await instances.lpContractInstance.methods
                .balanceOf(address)
                .call();

            const amount = window.web3.utils.toBN(new BigNumber(newLPBalance).minus(new BigNumber(oldLPBalance)));
            const res = await instances.lpContractInstance.methods
                .approve(
                    contractAddresses.mtlxMigrate,
                    amount
                    // window.web3.utils.toBN(userInfo.amount)
                )
                .send({ from: address, gasFee: gasFee });
            console.log(res);

            setLoadingText("Migrating PancakeSwap V1 LP tokens to V2...");
            await instances.mtlxMigratorContractInstance.methods
                .migrate(
                    contractAddresses.mtlx,
                    contractAddresses.usdt,
                    amount,
                    // window.web3.utils.toBN(userInfo.amount),
                    1,
                    1
                )
                .send({ from: address, gasFee: gasFee });

            const amountv2 = await instances.pancakePairContractInstance.methods
                .balanceOf(address)
                .call();

            setLoadingText("Approving PancakeSwap V2 LP token management by Amplify...");
            await instances.pancakePairContractInstance.methods
                .approve(
                    contractAddresses.amplify,
                    window.web3.utils.toBN(amountv2)
                )
                .send({ from: address, gasFee: gasFee });

            // setLoadingText("Approving PancakeSwap V2 LP token management by Proxy contract...");
            await instances.pancakePairContractInstance.methods
                .approve(
                    contractAddresses.proxy,
                    window.web3.utils.toBN(amountv2)
                )
                .send({ from: address, gasFee: gasFee });

            setLoadingText("Depositing PancakeSwap V2 LP tokens to Amplify...");
            await instances.amplifyContractInstance.methods
                .deposit(1, window.web3.utils.toBN(amountv2))
                .send({ from: address, gasFee: gasFee });

            // await props.updateStakedAmount(
            //     address,
            //     savedStakedAmount * -1,
            //     rewards * -1,
            //     "BUSD",
            //     56
            // );
            props.updateBalances();
            setLoading(false);
            setLoadingText("");
        } catch (err) {
            console.log("Migrate ", err);
            await props.updateStakedAmount(
                address,
                savedStakedAmount * -1,
                rewards * -1,
                "BUSD",
                56
            );
            props.updateBalances();
            setLoading(false);
            setLoadingText("");
        }
    };

    return (
        <Button
            onClick={onSubmit}
            style={{ color: "white" }}
            className={classes.ButtonUi}
            disabled={
                isMigrated || (!isMigrated && (parseFloat(lpTokensStaked) === 0 || lpTokens > 0))
            }
        >
            Migrate to v2
        </Button>
    );
};

export default Migrate;
