export const contractAddressesObj = {
  1: {
    mtlx: "0x2e1E15C44Ffe4Df6a0cb7371CD00d5028e571d14",
    amplify: '0xEBF56FB1C3029115555680aF6bfD97B6CF541bAB',
    lp: '0x1a57ec5459928389fBB5612fF2a5e0b534fD9E2E',
    proxy: '0xd60C24fda99a50DAB440f3B9B2Bf611C0852e2bD',
    usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    registry: '0xb872B19f6682F1DB65C8351233085bAd5c7b1c13',
    HUniswapV2: '0x5c260c28DfE03c5eb2829BFC66B07EbBF349fe1c',
    HERC20TokensIn: '0x871D6447879d7ccDadC80457731cc1722A8e8aCe',
    UniswapV2Router: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    lpETH: '0xa71d014ffc0d2aff34d1fe1672dcdef6ec00af66',
    eth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },
  56: {
    mtlx: "0x5921dee8556c4593eefcfad3ca5e2f618606483b",
    amplify: '0x73Ef71e8774dFDd7857Bf02C15AC2dF122dC8c91',
    lp: '0x396E3474A8D1B5882072a4a181b80C52b9fed479',
    proxy: '0x916EdF608c0e7aAE2E26B283a93fc26f2CD8C715',
    usdt: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    registry: '0x69Ba2A6F5ac0183B3bB1623877a6e42cff5E1A58',
    HUniswapV2: '0xd60C24fda99a50DAB440f3B9B2Bf611C0852e2bD',
    HERC20TokensIn: '0x871D6447879d7ccDadC80457731cc1722A8e8aCe',
    UniswapV2Router: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
    lpETH: '0xa71d014ffc0d2aff34d1fe1672dcdef6ec00af66',
    eth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    mtlxMigrate: '0x65B33c243E73A8fdEC7DC993c7223c7487Fa3E75',
    lpV2: '0x02b0b1229a918815e872c1e4d4c27d1df8290c90',
    hpancake: '0xc5Fb9549e81aD53f957cf3BF7403b908bC819979'
  }
}

export const stakeURL = "https://europe-west2-fetch-ai-sandbox.cloudfunctions.net/stake_amount";
export const mtlxrewardsURL = "https://europe-west2-fetch-ai-sandbox.cloudfunctions.net/mtlx_reward";
export const mtlxLeftURL = "https://europe-west2-fetch-ai-sandbox.cloudfunctions.net/get_rewards_distributed";

export const RewardPoolAddress = "0xebf56fb1c3029115555680af6bfd97b6cf541bab";
export const multiplier = 18
export const usdtMultiplier = 6
export const gasFee = 65000000
export const gasLimit = 7000000
export const networkId = 56
export const networkName = 'BSC Mainnet'
export const slippage = 0.96
export const totalTokens = 250000;
export const estimatedGas = 0.1;

export const networkNames = {
  1: 'Mainnet',
  42: 'kovan',
  97: 'bsc-testnet',
  56: 'BSC-MAINNET'
}

export const networks = {
  kovan: 42,
}

export const truncateDecimals = (amount, decimalDigit) => {
  if (!amount) amount = 0;
  let amountValue = amount.toString();
  if (amountValue.indexOf(".") > 0) {
    amountValue = amountValue.slice(0, (amountValue.indexOf(".")) + decimalDigit + 1)
  } else {
    amountValue = (amountValue + '.').padEnd(decimalDigit, "0")
  }
  return Number(amountValue).toFixed(decimalDigit)
}