import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import useStyles from './index.style';
import CssTextField from './CssTextField';

const StakePanel = (props) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.textStyle}>Amount to stake</Typography>
      <div style={{ display: 'flex' }}>
        <Grid item xs={9}>
          <CssTextField
            color="primary"
            placeholder="Enter Amount..."
            type="number"
            fullWidth
            value={props.amountToStake}
            onChange={props.handleInputChange}
            InputProps={{
              endAdornment: (
                <Button
                  className={classes.maxButton}
                  onClick={props.handleMaxInput}
                >Max</Button>
              ),
              className: classes.input,
            }}
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item xs={3}>
          <Button
            // variant="contained"
            className={classes.stakeButtonDiv}
            onClick={props.stakeLPTokens}
          >Stake</Button>
        </Grid>
      </div>
      <Typography className={classes.errorText}>{props.errorText}</Typography>
      <br />
      <div>
        <Typography className={classes.linkText} >Please use this option to provide liquidity via PancakeSwap pool tokens once you have added liquidity to
      {props.pool === '0' ?
          (props.networkSelected === 1 ?
            <a className={classes.link} href="https://info.uniswap.org/pair/0x1a57ec5459928389fbb5612ff2a5e0b534fd9e2e" target="_blank" rel="noopener noreferrer">
              &nbsp;MTLX-USDT </a>
            :
            <a className={classes.link} href="https://pancakeswap.info/pair/0x02B0B1229A918815E872c1E4D4c27d1DF8290C90" target="_blank" rel="noopener noreferrer">
              &nbsp;anyMTLX-BUSD </a>
          )
          :
          <a className={classes.link} href="https://info.uniswap.org/pair/0xa71d014ffc0d2aff34d1fe1672dcdef6ec00af66" target="_blank" rel="noopener noreferrer">
            &nbsp;MTLX-ETH </a>
        } pool</Typography>
      </div>
    </>
  );
};

export default StakePanel;