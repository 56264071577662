import React from 'react';
import useStyles from './index.style';
import { Typography, Grid, Button } from '@material-ui/core';

const RedeemPanel = (props) => {
  const classes = useStyles();
  return (
    <>
      <br />
      <div className={classes.buttonContainer}>
        <div style={{ display: 'flex' }}>
          <Grid item xs={6}>
            <Button className={classes.buttonStyle} disabled={props.mtlxRewards === 0 || (!props.isMigrated)} onClick={props.claimMTLXRewards}>Claim anyMTLX Rewards</Button>
          </Grid>
          <Grid item xs={6} style={{ marginLeft: 16 }}>
            <Button className={classes.redeemButtonStyle} onClick={props.unstakeUSDT} disabled={props.lpTokensStaked === 0 || (!props.isMigrated)}>Exit: Claim and Redeem All</Button>
          </Grid>
        </div>
        <br />
        <Typography style={{
          textAlign: 'center', color: '#7C7C7D',
          fontWeight: 300,
        }} className={classes.textStyle}>Please use this option to provide liquidity using{" "}{props.pool === "0"? props.networkSelected === 1? "USDT": "BUSD": "ETH"}{" "}only</Typography>
      </div>
      <br />
    </>
  );
};

export default RedeemPanel;
