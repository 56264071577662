import '@metamask/legacy-web3';
import Web3 from 'web3';
import { mtlxLeftURL, stakeURL, mtlxrewardsURL, networkId } from '../constants';
import Torus from '@toruslabs/torus-embed';

export const isMetamaskInstalled = () => {
  return !!window.ethereum || !!window.web3;
}

export const initializeWeb3 = async () => {
  // window.web3 = new Web3('https://bsc-dataseed1.binance.org:443');
  // console.log('window.web3:=============', window.web3)
  window.web3 = new Web3(window.ethereum);
  // window.ethereum.autoRefreshOnNetworkChange = false;
}

export const askPermission = async () => {
  try {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  } catch (error) {
    throw new Error(error);
  }
}

export const initializeTorusWallet = async (networkId) => {
  const torus = new Torus({
    buttonPosition: "bottom-right"
  });
  const network = {
    56: { host: 'https://bsc-dataseed1.binance.org', chainId: 56 }
  }
  try {
    await torus.init({ showTorusButton: true, network: network[networkId] });
    await torus.login();
    window.web3 = new Web3(torus.provider);
    return true
  } catch (error) {
    await torus.cleanUp();
    console.log(error)
    return false
  }
}

export const getNetworkId = async () => window.web3.eth.net.getId();

export const getAccounts = async () => window.web3.eth.getAccounts();

export const getAccountAddress = async () => {
  const [address] = await window.web3.eth.getAccounts();
  return address
}

export const getNetworkName = () => {
  return window.web3.eth.net.getNetworkType()
}

export const isUserLoggedIn = () => new Promise((resolve, reject) => {
  window.web3.eth.getAccounts((err, accounts) => {
    if (err != null) {
      console.log(err)
      reject(err);
    }
    resolve(accounts.length !== 0)
  });
});

export const metaMaskAccountsChanged = (callback) => {
  window.ethereum.on('chainChanged', callback)
  window.ethereum.on('accountsChanged', callback);
}

export const getStakedAmount = async (userAddress, tokenType = 'USDT', networkID=networkId) => {
  try {
    const response = await fetch(`${stakeURL}?address='${userAddress}'&network_id='${networkID}'&token_type='${tokenType}'`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Basic ${process.env.REACT_APP_CLOUD_FUNCTION}`,
        'Content-Type': 'application/json'
      }),
    })
    const result = await response.text()
    const data = JSON.parse(result)
    if (data.result) return data.result[0].sum
    else return null;
  } catch (error) {
    throw new Error(error)
  }
}

export const getMTLXRewards = async (userAddress, tokenType = 'USDT', networkID=networkId) => {
  try {
    const response = await fetch(`${mtlxrewardsURL}?address='${userAddress}'&network_id='${networkID}'&token_type='${tokenType}'`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Basic ${process.env.REACT_APP_CLOUD_FUNCTION}`,
        'Content-Type': 'application/json'
      }),
    })
    const result = await response.text()
    const data = JSON.parse(result)
    if (data.result) return data.result[0].sum
    else return null;
  } catch (error) {
    throw new Error(error)
  }
}

export const getMtlxLeft = async (networkID=networkId) => {
  try {
    const response = await fetch(`${mtlxLeftURL}?&network_id='${networkID}'`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Basic ${process.env.REACT_APP_CLOUD_FUNCTION}`,
        'Content-Type': 'application/json'
      }),
    })
    const result = await response.text()
    const data = JSON.parse(result)
    if (data.result) return data.result[0].total_reward_distributed
    else return null;
  } catch (error) {
    throw new Error(error)
  }
}

export const updateStakedAmount = async (address, amount, mtlxReward = 0, tokenType = 'USDT', networkID=networkId) => {
  try {
    const response = await fetch(stakeURL, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Basic ${process.env.REACT_APP_CLOUD_FUNCTION}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        address,
        amount,
        network_id: `${networkID}`,
        mtlx_reward: mtlxReward,
        token_type: tokenType,
      }),
    })
    const result = await response.text()
    const data = JSON.parse(result)
    if (data.result) return data.result
    else return []
  } catch (error) {
    throw new Error(error)
  }
}