import huniswapV2Mainnet from '../ABI/HUNISWAPV2_ABI.json';
import huniswapV2BSC from '../ABI/HUNISWAPV2_BSC_ABI.json';

class HuniSwapV2 {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(networkId === 1 ? huniswapV2Mainnet : huniswapV2BSC, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new HuniSwapV2(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;