import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppHeader from '../AppHeader/AppHeader';
import useStyles from './index.style';
import WalletModal from '../../component/WalletModal';

const LockScreen = (props) => {

  const isMinHeight = useMediaQuery('(min-height:770px)');

  const classes = useStyles()
  return (
    <React.Fragment>
      <AppHeader />
      <div className={classes.image} style={{ height: isMinHeight && '100%' }}>
        <WalletModal open={true} />
      </div>
    </React.Fragment >
  );
}

export default LockScreen;
