import hpancakeABI from '../ABI/HPANCAKE_ABI.json';

class HPanCake {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(hpancakeABI, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new HPanCake(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;
