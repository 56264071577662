import mtlxMainnet from '../ABI/MTLX_ABI.json';
import mtlxBSC from '../ABI/MTLX_BSC_ABI.json';

class Mtlx {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(networkId === 1 ? mtlxMainnet : mtlxBSC, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new Mtlx(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;