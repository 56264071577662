import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    height: 51,
    paddingRight: '0 !important',
    fontSize: 18,
    fontWeight: 300,
    '& input': {
      paddingRight: 12,
      paddingLeft: 12,
    },
    color: 'white',
    background: '#2C2D3D',
    '&:before, &:hover, &:after, &:focus, &.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  },
  maxButton: {
    padding: '1px 2px',
    fontSize: 18,
    fontWeight: 400,
    background: 'rgba(103, 218, 216, 0.15)',
    marginRight: 8,
    color: '#68DBDA'
  },
  textStyle: {
    color: '#7C7C7D',
    fontWeight: 300,
  },
  stakeButtonDiv: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    background: '#68DBDA',
    color: 'black',
    fontWeight: 500,
    fontSize: '14px',
    '&:hover': {
      background: '#68DBDA',
      opacity: 0.5,
    }
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
  link: {
    textDecoration: 'none',
    color: '#68DBDA',
  },
  linkText: {
    color: '#7C7C7D',
    fontWeight: 300,
    textAlign: 'center',
  }
}));

export default useStyles;