import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dotted: {
    display: "flex",
    background: '#222330',
    borderBottom: '1px solid #2d2e3d',
    position: "relative",
    padding: "10px 20px",
    '&:last-child': {
      borderBottom: 'none',
      padding: "9px 20px 10px",
    },
    '& .MuiTypography-root': {
      fontSize: "14px",
      fontFamily: "GTWalsheimLight",
    },
  },
  typography: {
    color: 'white',
    fontSize: "14px",
    fontWeight: 300,
  },
  greyTypography: {
    color: '#9098AC',
  },
  cardContent: {
    padding: "8px 0px 24px",
    fontSize: "14px",
  },
  cardContentLight: {
    color: '#7C7C7D',
    fontSize: 14
  },
  cardHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: "8px 0",
    color: 'white',
    '& .MuiTypography-root': {
      fontFamily: `"GTWalsheimMedium"`,
    }
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitle: {
    color: 'white',
    fontSize: '16px',
    fontFamily: "GTWalsheimMedium",
  },
  netvalue: {
    height: '168px',
    left: '0px',
    top: '0px',
    background: '#212230',
    border: '1px solid #F97E5D',
    boxSizing: 'border-box',
    // boxShadow: '0px 0px 36px rgba(249, 126, 93, 0.4)',
  }
}));

export default useStyles;