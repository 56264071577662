import PancakePairMain from "../ABI/PancakePair_ABI.json";

class PancakePair {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(
      networkId === 56 ? PancakePairMain : "",
      address
    );
  }

  getInstance = async () => this.UserContract;
}

let contract = null;
const getContract = (address, networkId) => {
  contract = new PancakePair(address, networkId);
  Object.freeze(contract);
  return contract;
};

export default getContract;
