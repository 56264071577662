import React from 'react';
import { connect } from 'react-redux';
import App from './App';
import { startSaga } from './rootSaga';
import dotenv from "dotenv";
import '../index.style';
import LockScreen from '../containers/LockScreen';
dotenv.config();
class Selector extends React.Component {
  render() {
    if (!this.props.user.address)
      return <LockScreen />
    return (
      <App />
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  network: state.user.network,
  networkID: state.user.networkID
});

const WrappedComponent = connect(mapStateToProps, null)(Selector);
const ComponentCall = () => {
  startSaga();
  return <WrappedComponent />
};

export default ComponentCall;