import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './index.style'

const TokenStausCard = (props) => {

  const classes = useStyles();

  return (
    <CardContent style={{ padding: 0, marginTop: 20 }}>
      <Typography className={classes.cardTitle}>Wallet Balance</Typography>
      <div style={{ display: "flex", }}>
        <Grid item md={4} xs={12}>
          <div className={classes.grid}>
            <Typography className={classes.contentText}>{props.pool === '0' ? (props.networkSelected === 1 ? 'USDT' : 'BUSD') : 'ETH'}</Typography>
            <Typography className={classes.incrementStyle}>{props.usdtBalance}</Typography>
          </div>
        </Grid>
        <br />
        <Grid item md={4} xs={12} style={{ paddingLeft: 32 }}>
        <div className={`${classes.grid}`}>
            <Typography className={classes.contentText}>anyMTLX</Typography>
            <Typography className={classes.incrementStyle}>{props.mtlxBalance}</Typography>
          </div>
        </Grid>
        <br />
        <Grid item md={4} xs={12} style={{ paddingLeft: 32 }}>
        <div className={classes.grid}>
            <Typography className={classes.contentText}>{props.networkSelected === 1 ? 'Uni-V2' : 'PANCAKE LP'}</Typography>
            <Typography className={classes.incrementStyle}>{parseFloat(props.lpTokens).toFixed(8)}</Typography>
          </div>
        </Grid>
      </div>
    </CardContent>
  );
};

export default TokenStausCard;
// import React from 'react';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import { Typography } from '@material-ui/core';
// import useStyles from './index.style'

// const TokenStausCard = (props) => {

//   const classes = useStyles();

//   return (
//     <Card className={classes.cardHeight}>
//       <CardContent>
//       <Typography className={classes.cardTitle}>Wallet Balance</Typography>            
//         <div className={classes.balanceContainer}>
//           <div className={classes.flexDiv}>
//             <Typography className={classes.cardContentLight}>{props.pool === '0' ? (props.networkSelected === 1 ? 'USDT' : 'BUSD') : 'ETH'}</Typography>
//             <Typography className={classes.cardContentDark}>{props.usdtBalance}</Typography>
//           </div>          
//           <div className={classes.flexDiv}>
//             <Typography className={classes.cardContentLight}>anyMTLX</Typography>
//             <Typography className={classes.cardContentDark}>{props.mtlxBalance}</Typography>
//           </div>          
//           <div className={classes.flexDiv}>
//             <Typography className={classes.cardContentLight}>{props.networkSelected === 1 ? 'Uni-V2' : 'PANCAKE LP'}</Typography>
//             <Typography className={classes.cardContentDark}>{parseFloat(props.lpTokens).toFixed(8)}</Typography>
//           </div>                     
//         </div> 
//       </CardContent>
//     </Card>
//   );
// };

// export default TokenStausCard;