import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Tooltip, Box } from '@material-ui/core';
import { TokenAmount as TokenAmountBSC, Token as TokenBSC, Fetcher as FetcherBSC, ChainId as ChainIdBSC, Route as RouteBSC } from "@pancakeswap-libs/sdk";
import {
  TokenAmount as TokenAmountV2,
  Token as TokenV2,
  Fetcher as FetcherV2,
  ChainId as ChainIdV2,
  Route as RouteV2,
} from "@pancakeswap-libs/sdk-v2";
import { ethers } from 'ethers';
import bigNumber from 'bignumber.js';
import useStyles from './index.style';
import { multiplier, contractAddressesObj, usdtMultiplier, gasFee } from '../../constants';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StakePanel from '../../component/StakePanel';
import UnstakePanel from '../../component/UnstakePanel';

function TabPanel(props) {
  const classes = useStyles()
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const LPTokensTransaction = (props) => {
  const classes = useStyles();

  const [amountToStake, setAmountToStake] = useState('');
  const [errorText, setErrorText] = useState('');
  const [tab, setTab] = useState(0);
  const tabsTitle = ["Stake", "Unstake"]

  const contractAddresses = contractAddressesObj[56];

  let provider = null;
  if (props.networkSelected === 56) {
    provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
  } else {
    provider = new ethers.providers.InfuraProvider("mainnet", process.env.REACT_APP_INFURA_PROVIDER);
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleInputChange = (event) => {
    setErrorText('');
    setAmountToStake(event.target.value);
  };

  const handleMaxInput = () => {
    setErrorText('');
    setAmountToStake(props.lpTokens);
  };

  const stakeLPTokens = async () => {
    let amount = parseFloat(amountToStake);

    if (amountToStake === '') {
      setErrorText('Please enter an amount');
      return;
    }

    if (parseFloat(amount) <= 0) {
      setErrorText('Amount can not be zero please enter valid amount');
      return;
    }

    if (parseFloat(amount) > props.lpTokens) {
      setErrorText(`Insufficient wallet balance (${props.lpTokens})`);
      return;
    }

    amount = '' + amountToStake;
    const index = amount.indexOf('.');
    if (index >= 0) {
      amount = amount.substr(0, index + 19);
    }

    try {
      props.setLoading(true);
      props.setLoadingText('Staking..');
      const userBalance = window.web3.utils.toBN(new bigNumber(amount).multipliedBy(10 ** multiplier));
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(props.isMigrated ? 1 : parseInt(props.pool), props.address).call();
      if (props.pool === '0') {
        await props.lpContract.methods.approve(
          contractAddresses.amplify,
          userBalance
        ).send({ from: props.address, gasFee: gasFee });
      } else {
        await props.lpethContract.methods.approve(
          contractAddresses.amplify,
          userBalance
        ).send({ from: props.address, gasFee: gasFee });
      }

      await props.amplifyContract.methods.deposit(props.isMigrated ? 1 : props.pool, userBalance).send({ from: props.address, gasFee: gasFee });
      const amountStaked = new bigNumber(amount).multipliedBy(10 ** multiplier).toNumber();
      await updateStakedAmount(amountStaked, pendingMTLX);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
  };

  const unStakeLPTokens = async () => {
    try {
      props.setLoading(true);
      props.setLoadingText('Unstaking..');
      
      //Get all acc rewards and store in pendingMTLX var
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(props.isMigrated ? 1 : parseInt(props.pool), props.address).call();
      //Convert pendingMTLX using BigNumber, store in MTLXreward
      const mtlxReward = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).toNumber();
      //Update CF
      await props.updateStakedAmount(props.address, 0, mtlxReward, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);

      const userInfo = await props.amplifyContract.methods.userInfo(props.isMigrated ? 1 : parseInt(props.pool), props.address).call();
      // const pendingMTLX = await props.drizzle.contracts.Amplify.methods.pendingMTLX(parseInt(props.pool), props.address).call();
      await props.amplifyContract.methods.withdraw(props.isMigrated ? 1 : props.isMigrated ? 1 : parseInt(props.pool), window.web3.utils.toBN(userInfo.amount)).send({ from: props.address, gasFee: gasFee });
      const savedStakedAmount = await props.getStakedAmount(props.address, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);
      // const mtlxReward = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).toNumber();
      const rewards = await props.getMTLXRewards(props.address, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);
      // console.log('savedStakedAmount', savedStakedAmount * (-1))
      await props.updateStakedAmount(props.address, savedStakedAmount * (-1), rewards * (-1), props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
  };

  const updateStakedAmount = async (amount, pendingMTLX) => {
    let usdtToken = null;
    let ethToken = null;
    let mtlxToken = null;

    if (props.isMigrated) {
      usdtToken = new TokenV2(
        ChainIdV2.MAINNET,
        contractAddresses.usdt,
        multiplier,
        "BUSD",
        "BUSD Token"
      );

      ethToken = new TokenV2(
        ChainIdV2.MAINNET,
        contractAddresses.eth,
        multiplier,
        "WETH",
        "WrappedETH"
      );

      mtlxToken = new TokenV2(
        ChainIdV2.MAINNET,
        contractAddresses.mtlx,
        multiplier,
        "MTLX",
        "MTLX-ERC20"
      );
    } else {
      usdtToken = new TokenBSC(
        ChainIdBSC.MAINNET,
        contractAddresses.usdt,
        multiplier,
        "BUSD",
        "BUSD Token"
      );

      ethToken = new TokenBSC(
        ChainIdBSC.MAINNET,
        contractAddresses.eth,
        multiplier,
        "WETH",
        "WrappedETH"
      );

      mtlxToken = new TokenBSC(
        ChainIdBSC.MAINNET,
        contractAddresses.mtlx,
        multiplier,
        "MTLX",
        "MTLX-ERC20"
      );
    }

    let firstToken = usdtToken;
    if (props.pool === '1') {
      firstToken = ethToken;
    }

    try {
      let pair = null;
      if (props.isMigrated) {
        pair = await FetcherV2.fetchPairData(
          firstToken,
          mtlxToken,
          provider
        );
      } else {
        pair = await FetcherBSC.fetchPairData(
          firstToken,
          mtlxToken,
          provider
        );
      }

      const lpTokensTotalSupply = props.pool === '0'
        ? await props.lpContract.methods.totalSupply().call()
        : await props.lpethContract.methods.totalSupply().call();
      const kLast = props.pool === '0'
        ? await props.lpContract.methods.kLast().call()
        : await props.lpethContract.methods.kLast().call();

      let lpToken = null;
      let first = null;
      let mtlx = null;

      if (props.isMigrated) {
        lpToken = new TokenV2(
          ChainIdV2.MAINNET,
          contractAddresses.lpV2,
          multiplier,
          props.pool === '0' ? await props.lpContract.methods.symbol().call() : await props.lpethContract.methods.symbol().call(),
          props.pool === '0' ? await props.lpContract.methods.name().call() : await props.lpethContract.methods.name().call()
        );
        first = pair.getLiquidityValue(
          firstToken,
          new TokenAmountV2(lpToken, lpTokensTotalSupply.toString()),
          new TokenAmountV2(lpToken, amount),
          true,
          kLast
        );
        mtlx = pair.getLiquidityValue(
          mtlxToken,
          new TokenAmountV2(lpToken, lpTokensTotalSupply.toString()),
          new TokenAmountV2(lpToken, amount),
          true,
          kLast
        );
      } else {
        lpToken = new TokenBSC(
          ChainIdBSC.MAINNET,
          contractAddresses.lp,
          multiplier,
          props.pool === '0' ? await props.lpContract.methods.symbol().call() : await props.lpethContract.methods.symbol().call(),
          props.pool === '0' ? await props.lpContract.methods.name().call() : await props.lpet.name().call()
        );
        first = pair.getLiquidityValue(
          firstToken,
          new TokenAmountBSC(lpToken, lpTokensTotalSupply.toString()),
          new TokenAmountBSC(lpToken, amount),
          true,
          kLast
        );
        mtlx = pair.getLiquidityValue(
          mtlxToken,
          new TokenAmountBSC(lpToken, lpTokensTotalSupply.toString()),
          new TokenAmountBSC(lpToken, amount),
          true,
          kLast
        );
      }

      let route = null;
      if (props.isMigrated) {
        route = new RouteV2([pair], mtlxToken);
      } else {
        route = new RouteBSC([pair], mtlxToken);
      }

      const mtlxReward = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).toNumber();
      const amountStaked = new bigNumber(mtlx.toExact()).multipliedBy(new bigNumber(route.midPrice.toSignificant(8))).plus(new bigNumber(first.toExact())).toNumber();
      await props.updateStakedAmount(props.address, amountStaked, mtlxReward, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);
      props.updateBalances();
      setAmountToStake('');
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
    }
  };


  const claimMTLXRewards = async () => {
    try {
      props.setLoading(true);
      const address = props.address;
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(props.isMigrated ? 1 : parseInt(props.pool), address).call();
      await props.amplifyContract.methods.withdraw(props.isMigrated ? 1 : parseInt(props.pool), 0).send({ from: address, gasFee: gasFee });
      const mtlxReward = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).toNumber();
      await props.updateStakedAmount(address, 0, mtlxReward, props.pool === '0' ? (props.networkSelected === 56 ? 'BUSD' : 'USDT') : 'ETH', props.networkSelected);
      props.updateBalances();
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoading(false);
    }
  };


  return (
    <CardContent className={classes.cardContent}>
      <Tabs TabIndicatorProps={{
        style: {
          backgroundColor: "#68dbda"
        }
      }} value={tab} onChange={handleChange} className={classes.tabs} >
        <Tab className={tab === 0 ? classes.tab : classes.disabledTab} label={
          tabsTitle[0]
        } />
        <Tab className={tab === 1 ? classes.tab : classes.disabledTab} label={
          tabsTitle[1]
        } />
        <Tooltip
          style={{ display: "inline", marginRight: "5px" }}
        >
          <Typography className={classes.icon}>{props.pool === '0' ? (props.networkSelected === 1 ? 'UNIV2-USDT' : 'PANCAKE LP') : 'UNIV2-ETH'}</Typography>
        </Tooltip>
      </Tabs>
      <TabPanel value={tab} index={0} className={`${classes.tabContainer} tabs`}>
        <StakePanel
          amountToStake={amountToStake}
          handleMaxInput={handleMaxInput}
          handleInputChange={handleInputChange}
          stakeLPTokens={stakeLPTokens}
          errorText={errorText}
          pool={props.pool}
          networkSelected={props.networkSelected}
        />
      </TabPanel>
      <TabPanel value={tab} index={1} className={`${classes.tabContainer} tabs`}>
        <UnstakePanel
          claimMTLXRewards={claimMTLXRewards}
          mtlxRewards={props.mtlxRewards}
          unStakeLPTokens={unStakeLPTokens}
          lpTokensStaked={props.lpTokensStaked}
          pool={props.pool}
          networkSelected={props.networkSelected}
        />
        {/* {renderUnstaking()} */}
      </TabPanel>
    </CardContent>
  );
};

export default LPTokensTransaction;