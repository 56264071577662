import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tab: {
    color: '#68dbda !important',
    fontSize: '16px !important',
    padding: 0,
    fontWeight: 'bold !important',
    textTransform: "none !important",    
    fontFamily: 'GTWalsheimMedium !important',
  },
  typography: {
    fontSize: 16,
    margin: theme.spacing(2)
  },
  disabledTab: {
    color: 'white !important',
    textTransform: "none !important",    
    fontSize: "16px !important",
    opacity: "1 !important",
    '&:hover': {
      color: '#68dbda !important',
    },
  },
  tabs: {
  },
  tabContainer: {
    background:'#222330',
    padding: "10px 12px",
  }, 
  tabPanel: {
    height: 'calc(100% - 48px)'
  },
  icon: {
    color: '#68dbda',
    height: "auto",
    margin: "auto",
    marginRight: 0,
  },
  cardContent: {
    padding: '10px 0 17px',
    color: 'white',
  }
}));

export default useStyles;